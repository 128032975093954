<template>
  <SchoolLeaveEntitlementTable
    :items="items"
    :is-busy="isBusy"
    :pagination="pagination"
    @navigateToIndividual="goToTeacherAssignment"
  >
  </SchoolLeaveEntitlementTable>
</template>

<script>
import SchoolLeaveEntitlementTable from "@/modules/school/components/management/leave/entitlement/SchoolLeaveEntitlementTable";
import { mapGetters } from "vuex";

export default {
  name: "TeacherLeaveAssignment",
  components: { SchoolLeaveEntitlementTable },
  data() {
    return {
      isBusy: false,
    };
  },
  mounted() {
    this.fetch();
  },
  beforeDestroy() {
    this.$store.commit("setTeacherList", []);
  },
  methods: {
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchTeacherList")
        .finally(() => (this.isBusy = false));
    },

    /**
     * Navigate to Teacher individual entitlement list
     *
     * @param data
     */
    goToTeacherAssignment(data) {
      console.log(data);
      this.$router.push({
        name: "teacherLeaveAssignment",
        query: { id: data.item.id, name: data.item.name },
      });
    },
  },
  computed: {
    ...mapGetters({
      items: "getTeacherList",
      pagination: "getTeacherListPagination",
    }),
  },
};
</script>

<style scoped></style>
