import { render, staticRenderFns } from "./TeacherLeaveAssignment.vue?vue&type=template&id=ad901a3e&scoped=true&"
import script from "./TeacherLeaveAssignment.vue?vue&type=script&lang=js&"
export * from "./TeacherLeaveAssignment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad901a3e",
  null
  
)

export default component.exports